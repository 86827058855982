<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="">STO Doc. Num.</vs-th>
                <vs-th sort-key="">DO [STO] Num.</vs-th>
                <vs-th sort-key="">GR [STO] Num.</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key="">Source Warehouse</vs-th>
                <vs-th sort-key="">Receive Date</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total QTY (UOM)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div v-if="tr.status == 0" class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit"
                                style="margin-right: 5px" @click="handleEdit(tr.sto_id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm"
                                @click="handleConfirm(tr.gr_id)" />
                        </div>
                        <div v-else class="vx-input-group flex">
                            <vs-button size="small" color="primary" icon-pack="feather" icon="icon-search" title="Show"
                                style="margin-right: 5px" @click="handleEdit(tr.sto_id)" />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.sto_code }}</vs-td>
                    <vs-td>{{ tr.do_code }}</vs-td>
                    <vs-td>{{ tr.gr_code }}</vs-td>
                    <vs-td>{{ tr.delivery_date }}</vs-td>
                    <vs-td>{{ tr.src_wh_code }} {{ tr.src_wh_name }}</vs-td>
                    <vs-td>{{ tr.receive_date }}</vs-td>
                    <vs-td>{{ tr.dest_wh_code }} {{ tr.dest_wh_name }}</vs-td>
                    <vs-td>{{ tr.gr_id == null ? tr.total_sku : tr.total_sku_gr }}</vs-td>
                    <vs-td>{{ tr.gr_id == null ? tr.quantity_uom : tr.quantity_uom_gr }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Open",
        },
    },
    data() {
        return {
            confirmId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handleCreate() {
            this.customerId = null;
            this.detail = true;
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.simpleSto.baseUrlPath, {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        source_warehouse_id: this.sourceWarehouseId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        // this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleConfirm(id) {
            this.confirmId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "warning",
                title: `Confirm`,
                text: "Confirm this data",
                accept: this.acceptConfirm,
            });
        },
        acceptConfirm() {
            this.$vs.loading();
            this.$http
                .put(this.$store.state.inbound.simpleSto.baseUrlPath + "/change-status/" + this.confirmId)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully confirm",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleEdit(id) {
            this.$router.push({
                name: this.$store.state.inbound.simpleSto.baseRouterName + "-edit",
                params: { id: id },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() { },
    watch: {
        // sourceWarehouseId() {
        //     this.getData();
        // },
        // transactionDate() {
        //     this.getData();
        // },
        // status() {
        //     this.getData();
        // },
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
  